<template>
  <el-dialog
    id="SelectContractDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="70%"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @keydown.enter.native="onFormEnter"
    destroy-on-close
  >
    <el-container v-loading="loading">
      <!-- <el-header class="header3" height="48"
        ><el-row type="flex" justify="start">
          <div>
            <el-input
              :placeholder="$t('Пошук')"
              style="width:308px;"
              prefix-icon="el-icon-search"
              clearable
              v-model="filterString"
              ref="filter"
              @keydown.esc.native="filterString = ''"
            />
          </div> </el-row
      ></el-header> -->
      <el-header class="header3" height="48"
        ><el-row type="flex" justify="start">
          <div>
            <el-input
              :placeholder="$t('Пошук')"
              style="width: 308px"
              prefix-icon="el-icon-search"
              clearable
              v-model="filterString"
              ref="inputFilterName"
              @keydown.esc.native="filterString = ''"
            />
          </div>
          <div class="push" style="padding-right: 19px" v-bind:class="{ invalid: !filterByDateValid }">
            <span>{{ $t("Період") }}: </span>
            <el-date-picker v-model="filterByDateFrom" type="date" format="dd.MM.yyyy" ref="date1"> </el-date-picker>
            <span> - </span>
            <el-date-picker v-model="filterByDateTo" type="date" format="dd.MM.yyyy" ref="date2"> </el-date-picker>
          </div> </el-row
      ></el-header>
      <div ref="flex_container" class="flex_container">
        <ag-grid-vue
          ref="SelectContractTable"
          id="SelectContractTable"
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          rowSelection="single"
          :defaultColDef="defaultColDef"
          :columnDefs="columns"
          :headerHeight="32"
          :rowData="contracts"
          :gridOptions="gridOptions"
          @selection-changed="onGridSelectionChanged"
          :overlayNoRowsTemplate="noRowsToShowTemplate"
          @gridReady="onGridReady"
          @sortChanged="onGridColumnsChange"
          @columnResized="onGridColumnsChange"
          @columnMoved="onGridColumnsChange"
          @displayedColumnsChanged="onGridColumnsChange"
          :modules="modules"
          :localeTextFunc="gridLocale"
          :enableBrowserTooltips="true"
        >
        </ag-grid-vue>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button" :disabled="!selectedContracts">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="onCancel" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { _ } from "vue-underscore";
  import { bus } from "@/main";
  import { AgGridVue } from "@ag-grid-community/vue";
  import { AllModules } from "@ag-grid-enterprise/all-modules";

  function parseError(error) {
    if (typeof error == "string") {
      return error;
    } else if (Array.isArray(error)) {
      return error.join("; ");
    } else return error;
  }

  export default {
    name: "SelectContract",
    props: ["busEvent"],
    components: { AgGridVue },
    data() {
      return {
        loading: false,
        filterString: "",
        modules: AllModules,
        dialogShow: false,
        dialogHeight: 0,
        resizeObserver: null,
        title: $tt("Пошук"),
        target: null,
        model: {},
        initModel: {},
        selectedContracts: null,
        waitForAnswer: false,
        keyUpTimer: null,
        filterHasChanged: false,
        gridApi: null,
        gridColumnApi: null,
        excludeForSelect: [],
        operation: "",
        noRowsToShowTemplate: `<div><img src="${require("../../../assets/icons/no_data.svg")}"></div>`,
        gridOptions: {
          suppressCellSelection: true,
          getRowNodeId: function(data) {
            return data._id;
          },
          immutableData: true,
          rowHeight: 32,
          floatingFiltersHeight: 32,
        },

        defaultColDef: {
          suppressNavigable: true,
          cellClass: "no-border",
          resizable: true,
          sortable: true,
          tooltipValueGetter: function(params) {
            return params.value;
          },
          floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
          suppressMenu: false,
          filter: "agTextColumnFilter",
          floatingFilterComponentParams: { suppressFilterButton: true },
        },
        columns: [
          {
            headerName: $tt("Назва"),
            field: "name",
            flex: 1,
          },
          {
            headerName: $tt("Статус"),
            field: "status",
            width: "150px",
            flex: 1,
            valueGetter: function(params) {
              var date = new Date();
              if (Date.parse(params.data.beginDate) > Date.parse(date)) return $tt("Буде");
              else if (Date.parse(params.data.endDate) < Date.parse(date)) return $tt("Завершено");
              else return $tt("Активний");
            },
          },
          {
            headerName: $tt("Замовник"),
            field: "customer",
            valueGetter: function(params) {
              if (params.data.customer) return `${params.data.customer.name}`;
              else return "";
            },
            width: "270px",
          },
          {
            headerName: $tt("Виконавець"),
            field: "executor",
            valueGetter: function(params) {
              if (params.data.executor) return `${params.data.executor.name}`;
              else return "";
            },

            width: "270px",
          },
          {
            headerName: $tt("Дата початку"),
            field: "beginDate",
            valueGetter: function(params) {
              return new Date(params.data.beginDate).toLocaleDateString();
            },
            width: "140px",
          },
          {
            headerName: $tt("Дата закінчення"),
            field: "endDate",
            valueGetter: function(params) {
              return new Date(params.data.endDate).toLocaleDateString();
            },
            width: "140px",
          },
          {
            headerName: $tt("Структури"),
            field: "structures",
            valueGetter: function(params) {
              if (params.data._structuresCount && params.data._structuresCount > 0) return `${params.data._structuresCount}`;
              else return $tt("Структур не обрано");
            },
            width: "170px",
          },
        ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
      };
    },
    created() {
      bus.$on(this.busEvent + ".show", (data) => {
        this.loading = true;
        this.title = (data && data.title) || vm.title;
        this.filterString = "";
        this.excludeForSelect = data && data.excludeForSelect ? data.excludeForSelect : [];
        this.operation = data && data.operation ? data.operation : "";
        this.dialogShow = true;
      });
      bus.$on(this.busEvent + ".hide", () => {
        this.dialogShow = false;
        this.resizeObserver = null;
      });
    },
    computed: {
      contracts() {
        return this.$store.getters["contracts/contractsStoreFiltered"]({
          byName: this.filterString,
          byDateFrom: this.filterByDateFrom,
          byDateTo: this.filterByDateTo,
        });
      },
      filterByDateValid() {
        if (this.filterByDateFrom && this.filterByDateTo) {
          if (Date.parse(this.filterByDateFrom) > Date.parse(this.filterByDateTo)) {
            return false;
          } else return true;
        } else return true;
      },
      filterByDateFrom: {
        get() {
          return this.$store.getters["main/getFilterByDateFrom"]();
        },
        set(value) {
          this.$store.dispatch("main/setFilterByDateFrom", value);
        },
      },
      filterByDateTo: {
        get() {
          return this.$store.getters["main/getFilterByDateTo"]();
        },
        set(value) {
          this.$store.dispatch("main/setFilterByDateTo", value);
        },
      },
    },
    watch: {
      dialogHeight(value) {
        var containerHeight = value - 72 - 48 - 16 - 48;
        // 48 - header 72 - footer 48 - find input 20 - padding 16 - 2 header
        this.$refs.flex_container.style.height = `${containerHeight}px`;
      },

      filterByDateFrom: {
        handler(val) {
          if (val && this.filterByDateTo) {
            if (Date.parse(val) > Date.parse(this.filterByDateTo)) {
              this.$message.warning($tt("Помилка в фільтрі періодів"));
            }
          }
        },
      },
      filterByDateTo: {
        handler(val) {
          if (val && this.filterByDateFrom) {
            if (Date.parse(this.filterByDateFrom) > Date.parse(val)) {
              this.$message.warning($tt("Помилка в фільтрі періодів"));
            }
          }
        },
      },
    },
    mounted() {},
    methods: {
      onGridReady() {
        this.gridColumnApi = this.gridOptions.columnApi;
        var state = this.$store.getters["main/userSettings"]("SelectContractGridColumnState");
        if (state) this.gridColumnApi.setColumnState(state);
      },
      observeHeight() {
        var that = this;
        if (!this.resizeObserver) {
          this.resizeObserver = new ResizeObserver(function() {
            that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
          });
          this.resizeObserver.observe(this.$el.children[0]);
        }
      },
      unobserveHeight() {
        this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
        this.resizeObserver = null;
        this.dialogHeight = 0;
      },
      onCommit() {
        if (this.selectedContracts && this.selectedContracts.length == 1) {
          this.$emit("commit", { contract: this.selectedContracts[0] });
          this.dialogShow = false;
        } else this.$message.error($tt("Будь ласка, оберіть один із рядків!"));
      },
      onCancel() {
        this.$emit("select-cancel");
        this.dialogShow = false;
      },
      onOpen() {
        this.observeHeight();
      },
      onOpened() {
        this.loading = false;
      },
      onClose() {
        this.unobserveHeight();
      },
      onGridSelectionChanged() {
        var rows = this.gridOptions.api.getSelectedRows();
        if (rows && rows.length > 0) {
          this.selectedContracts = rows;
        } else {
          this.selectedContracts = null;
        }
      },
      onGridColumnsChange() {
        var that = this;
        if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
        this.changeColumnsTimer = setTimeout(() => {
          var state = that.gridColumnApi.getColumnState();
          clearTimeout(that.changeColumnsTimer);
          that.$store.dispatch("main/updateUserSettings", { SelectContractGridColumnState: state });
        }, 500);
      },
      gridLocale(key, defaultValue) {
        var localized = $tt("grid." + key);
        return localized ? localized : defaultValue;
      },
    },
  };
</script>

<style lang="scss" scope>
  #SelectContractDialog .header2 {
    background: #fff;
    line-height: 32px;
    border-bottom: 1px solid #ccc !important;
    padding: 0 6px !important;
    align-items: center;
    margin-left: -16px;
    margin-right: -16px;
  }

  #SelectContractDialog.my-dialog .el-dialog--center .el-dialog__body {
    padding: 16px 16px 0 16px !important;
  }

  .flex_container {
    width: 100%;
  }
</style>
